.slide-one-item{
    position: relative;
    z-index: 1;
}

.owl-carousel{
    width: 100%;
}

.owl-carousel .owl-item {
    display: none;
    animation: fade-out 500ms forwards;
}

.owl-carousel .owl-item.active {
    display: block;
    animation: fade-in 500ms forwards;
}